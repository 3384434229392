
























































import Api from "@/includes/logic/Api";
import { errorNotification } from '@/includes/NotificationService'
import { Board, Style, WatermarkPosition, WatermarkType } from "@/views/posts-planner/posts.types";
import PostStylesSetup from "@/views/posts-planner/components/PostStylesSetup.vue";
import PageTitle from "@/components/PageTitle.vue";

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

import { isEmpty } from 'lodash'
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Component from "vue-class-component";
import Vue from "vue";

@Component({
  'components': {
    PageTitle,
    PostStylesSetup,
    ConfigField,
    ValidationProvider,
    ValidationObserver
  }
})
export default class StylePage extends Vue {

  boardTokenIdCode = ''

  isObjectSet = false

  styleObject_name = ''

  styleObject = {
    watermark_type: WatermarkType.None,
    watermark_position: WatermarkPosition.Center,
    watermark_data: null,
    prefix_message: [ {
      text: "",
      buttons: [],
      remove_after: 0,
      remove_previous: false,
      send_after: 0,
      pin: false,
      disable_link_preview: false,
      disable_notify: false,
    } ],
    postfix_message: [ {
      attachments: [],
      text: "",
      buttons: [],
      remove_after: 0,
      remove_previous: false,
      send_after: 0,
      pin: false,
      disable_link_preview: false,
      disable_notify: false,
    } ]
  } as Style

  styles: Record<any, Style> = {}

  isStyleLoading = false

  saveStyle(): void {
    this.isStyleLoading = true

    try {
      const styleObjectCopy = { ...this.styleObject }
      if (styleObjectCopy.watermark_type === 'File') {
        styleObjectCopy.watermark_data = styleObjectCopy.watermark_data.join()
      }
      if (this.getActionType() === 'edit') {
        if (this.getStyleIndex() !== this.styleObject_name) {
          this.$delete(this.styles, this.getStyleIndex())
        }
      }
      this.$set(this.styles, this.styleObject_name, styleObjectCopy)
      Api.setBoardConfig('tg', {
        board: this.boardTokenIdCode,
        config: { styles: this.styles }
      })
        .then(() => {
          this.$router.push({ name: 'board_styles' })
        })
        .catch(errorNotification)
        .finally(() => this.isStyleLoading = false)
    } catch (e) {
      this.isStyleLoading = false
      errorNotification(e)
    }
  }

  get boardTokenId() {
    const board:Board = this.$store.state.postsPlanner.boards.slice().find((b: Board) => String(b.board.token_id) === this.$route.params.boardId)
    this.boardTokenIdCode = `${ board.board.token_id }.${ board.board.token_code }`
    return `${ board.board.token_id }.${ board.board.token_code }`
  }

  mounted(): void {
    this.isStyleLoading = true

    Api.getBoardConfig('tg', { board: this.boardTokenId })
      .then(({ data }) => {
        data.config.styles === null ? this.styles = {} : this.styles = data.config.styles
        if (!data.config.styles || !Object.keys(data.config.styles).length || this.getActionType() === 'new') {
          this.isObjectSet = true
        } else {
          try {
            const key = this.getStyleIndex()
            let style = this.styles[key]
            if (key === this.getStyleIndex()) {
              if (style.watermark_type === 'File') {
                style.watermark_data = style.watermark_data.split()
              }

              this.styleObject = { ...style }
              this.styleObject_name = key
              this.isObjectSet = true
            }
          } catch (e) {
            errorNotification(e)
            this.$router.push({ name: 'board_styles' })
          }

        }
      })
      .catch(errorNotification)
      .finally(() => {
        this.isStyleLoading = false
      })
  }

  get disabled(): boolean {
    return this.styleObject.watermark_type !== 'None' && isEmpty(this.styleObject.watermark_data)
  }

  getStyleIndex(): any {
    return this.$route.query.styleId
  }

  getActionType(): string {
    return this.$route.params.actionType
  }

}
