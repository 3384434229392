var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"post-styles-setup"},[(_vm.styleCopy)?_c('div',[_c('div',{staticClass:"mr-5"},[_c('multi-message-editor-with-media-input',{staticClass:"w-full",attrs:{"setup":{
          'func': _vm.inputSetup,
          'args': {
            'model': _vm.styleCopy,
            'key': 'prefix_message',
            'availableButtonsTypes': _vm.defaultMessageEditorButtons.filter(function (b) { return b.name !== 'TriggerCall'; }),
            'base-api-url': 'api.chatkeeper.app/cabinet',
            'targetToUpload': function () { return _vm.boardUploadTarget(_vm.board); },
            'blacklistMediaButtonTypes': [ _vm.FileType.MessagePreview ]
          }
        }}}),_c('multi-message-editor-with-media-input',{staticClass:"w-full",attrs:{"setup":{
          'func': _vm.inputSetup,
          'args': {
            'model': _vm.styleCopy,
            'key': 'postfix_message',
            'availableButtonsTypes': _vm.defaultMessageEditorButtons.filter(function (b) { return b.name !== 'TriggerCall'; }),
            'base-api-url': 'api.chatkeeper.app/cabinet',
            'targetToUpload': function () { return _vm.boardUploadTarget(_vm.board); },
            'blacklistMediaButtonTypes': [ _vm.FileType.MessagePreview ]
          }
        }}})],1),_c('ConfigField',{staticClass:"m-2 mt-5 mb-5",attrs:{"title":_vm.$t('field_watermark_type_title')}},[_c('v-select',{staticClass:"chat-properties__selectType mt-2 vu-dropdown-toggle",attrs:{"options":_vm.watermarkTypes,"label":"label","reduce":function (style) { return style.value; }},on:{"input":_vm.onSelectChange},model:{value:(_vm.styleCopy.watermark_type),callback:function ($$v) {_vm.$set(_vm.styleCopy, "watermark_type", $$v)},expression:"styleCopy.watermark_type"}})],1),(_vm.styleCopy.watermark_type !== 'None' && _vm.styleCopy.watermark_type !== null )?_c('select-input',{attrs:{"setup":{
        'func': _vm.inputSetup,
        'args': {
          'model': _vm.styleCopy,
          'key': 'watermark_position',
          'options': _vm.watermarkPosition,
          'multiple': false,
          'clearable': false,
        },
      }}}):_vm._e(),(_vm.styleCopy.watermark_type === 'Text')?_c('text-input',{attrs:{"setup":{
        'func': _vm.inputSetup,
        'args': {
          'model': _vm.styleCopy,
          'key': 'watermark_data',
          'validation': 'required',
        },
      }}}):_vm._e(),(_vm.styleCopy.watermark_type === 'File')?_c('file-uploader-targets-input',{staticClass:"watermark_uploader",attrs:{"setup":{
        'func': _vm.inputSetup,
        'args': {
          'model': _vm.styleCopy,
          'key': 'watermark_data',
          'multiple': false,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'available-mime-types': ['image/png', 'image/jpeg' ],
          'targetToUpload': function () { return _vm.boardUploadTarget(_vm.board); }
        },
      }}}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }