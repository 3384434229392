import { MessageEditorWithMediaData } from "piramis-base-components/src/components/MessageEditorWithMedia/types"

export type PostData = {
  board?: string
  targets: Array<number> | null
  schedule?: Schedule | null
  post: IPost
  tags?: Array<any>
  category?: any
  creative_id?: any
  state?: string
  key?: string
  run_time?: Array<string> | string
  origin?: number | null
  style?: Style | null
}

export type Style = {
  id?: any,
  watermark_type?: WatermarkType | null
  watermark_data?: any
  watermark_position?: WatermarkPosition | null
  prefix_message?: Array<MessageEditorWithMediaData> | null
  postfix_message?: Array<MessageEditorWithMediaData> | null
}

export enum WatermarkType {
  None = "None",
  File = "File",
  Text = "Text"
}

export enum WatermarkPosition {
  Flip = "Flip",
  Center = "Center",
  TopRight = "TopRight",
  TopLeft = "TopLeft",
  BottomRight = "BottomRight",
  BottomLeft = "BottomLeft",
}

export type Schedule = {
  run_time: string
  period: Period | null
  timezone: string
}

export type Conditions = {
  type: string
  values: any
}

export type Period = {
  interval: number | null
  unit: string
  conditions: Array<Conditions> | null
}

export type IPost = {
  delayed_actions: Array<PostAction> | null
  reaction_actions: Array<PostReaction> | null
  message: PostMessage | Poll | Quiz
}

export type PostAction = {
  delay: number,
} & IPostActionMessage

export type PostReaction = {
  reaction: string,
  lower_bound: number,
} & IPostActionMessage

export type IPostActionMessage = {
  action: {
    type: PostActionType
    message?: PostMessage,
  },
}

export enum PostActionType {
  DeletePostAction = "DeletePostAction",
  SendCommentAction = "SendCommentAction",
  EditPostAction = "EditPostAction",
  UnpinPostAction = "UnpinPostAction"
}

export type PostMessage = {
  type: "Post"
  variants: Array<MessageVariants>
}

export type Attachments = {
  type: string
  file: string
}

export type MessageVariants = {
  board?: string,
  chat_id?: number,
  text: string
  buttons: Array<any>
  pin?: boolean
  attachments?: Array<Attachments>,
  send_after?: number
  disable_notify?: boolean
  remove_previous?: boolean
  remove_after?: number,
  disable_link_preview?: boolean
}

export type Board = {
  avatar: string
  board: {
    token_id: number,
    token_code: string,
  }
  title: string,
  timezone: string,
  targets: Array<Target>
}

export type Target = {
  id: number,
  photo: string,
  title: string,
  weight: number
  code: any,
}

export type PostQuestionnaire = {
  text: string,
  questions: Array<string>,
  is_anonymous: boolean,
  open_period: number
  topics: Array<number>
}

export type Poll = {
  type: "Poll"
  allows_multiple_answers: boolean
} & PostQuestionnaire

export type Quiz = {
  type: "Quiz"
  correct_option_id: string,
  explanation: string
} & PostQuestionnaire

export type PostType = 'Poll' | 'Post' | 'Quiz'
